import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="krebstherapie" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Begleitende Krebstherapie`}</h1>
      <h3 {...{
        "id": "begleitende-krebstherapie"
      }}>{`Begleitende Krebstherapie`}</h3>
      <p>{`Krebs ist eine der häufigsten Krankheiten überhaupt. Krebs ist in Deutschland die zweithäufigste Todesursache. Neben der schulmedizinisch häufig notwendigen Krebstherapie durch Chirurgie, Chemotherapie und/oder Bestrahlung hat sich die biologische Krebstherapie als komplementäre, d.h. ergänzende und begleitende Therapie etabliert.`}</p>
      <p>{`Gerade in der Krebsnachsorge und Rezidivprophylaxe (Rückfall-Vorsorge), wo es nach der Tumordestruktion um Immunstärkung, Aufbau und neuen Lebensmut geht, hat die biologische Medizin bewährte Therapien:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/vitamin-c-infusion"
          }}>{`Vitamin-C-Infusion`}</a></li>
      </ul>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      